<template>
  <div>
    <div>
      <AlertErrors :form="filters" />

      <b-form @submit.prevent="fetchAnalytics()" inline>
        <b-input-group size="sm" class="mr-sm-3 mb-sm-2">
          <template #prepend>
            <b-input-group-text class="text-muted">
              Creation From Date
            </b-input-group-text>
          </template>

          <b-form-input
            v-model="filters.creation_from_date"
            type="date"
            :state="filters.errors.has('creation_from_date') ? false : null"
            :required="filters.creation_to_date != ''"
          ></b-form-input>
        </b-input-group>

        <b-input-group size="sm" class="mr-sm-3 mb-sm-2">
          <template #prepend>
            <b-input-group-text class="text-muted">
              Creation To Date
            </b-input-group-text>
          </template>

          <b-form-input
            v-model="filters.creation_to_date"
            type="date"
            :state="filters.errors.has('creation_to_date') ? false : null"
          ></b-form-input>
        </b-input-group>

        <b-input-group size="sm" class="mr-sm-3 mb-sm-2">
          <template #prepend>
            <b-input-group-text class="text-muted">
              Overdue Resolved Hour
            </b-input-group-text>
          </template>

          <b-form-input
            v-model="filters.overdue_resolved_hour"
            type="number"
            placeholder="Default: 3 hour"
            :state="filters.errors.has('overdue_resolved_hour') ? false : null"
          ></b-form-input>
        </b-input-group>

        <b-button type="submit" variant="primary" block>Analyse</b-button>
      </b-form>
    </div>

    <div class="mt-5">
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-skeleton width="40%" height="50px"></b-skeleton>
        </template>

        <h1 class="text-dark">
          Incidents Count: {{ analytics ? analytics[0].incidents_count : 0 }}
        </h1>
      </b-skeleton-wrapper>
    </div>

    <!-- Resolution & Overdue -->
    <b-row class="mt-5">
      <b-col>
        <div class="text-center border-bottom mb-3">
          <h6 class="font-weight-bold mb-0">Resolution Status</h6>
          <small>
            Showing the different resolution statuses and their percentages
          </small>
        </div>

        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-img></b-skeleton-img>
          </template>

          <apexchart
            type="pie"
            :options="resolutionStatusChartOptions"
            :series="resolutionStatusChartSeries"
          ></apexchart>
        </b-skeleton-wrapper>
      </b-col>

      <b-col>
        <div class="text-center border-bottom mb-3">
          <h6 class="font-weight-bold mb-0">Overdue Incidents</h6>
          <small>
            Showing the overdue incidents within
            {{
              filters.overdue_resolved_hour ? filters.overdue_resolved_hour : 3
            }}
            hour{{
              (filters.overdue_resolved_hour
                ? filters.overdue_resolved_hour
                : 3) > 1
                ? "s"
                : ""
            }}
          </small>
        </div>

        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-img></b-skeleton-img>
          </template>

          <apexchart
            type="pie"
            :options="overdueChartOptions"
            :series="overdueChartSeries"
          ></apexchart>
        </b-skeleton-wrapper>
      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-col>
        <div class="text-center border-bottom mb-3">
          <h6 class="font-weight-bold mb-0">Incident Category</h6>
          <small>
            Showing the different incident categories and their overdue rate
          </small>
        </div>

        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-img></b-skeleton-img>
          </template>

          <apexchart
            type="bar"
            :options="categoryOverdueChartOptions"
            :series="categoryOverdueChartSeries"
          ></apexchart>
        </b-skeleton-wrapper>
      </b-col>
      <b-col></b-col>
    </b-row>
  </div>
</template>

<script>
import { endpoints } from "@/utils/endpoints";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "IncidentsAnalytics",
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      loading: true,
      filters: new this.$Form({
        creation_from_date: "",
        creation_to_date: "",
        overdue_resolved_hour: "",
      }),
      analytics: null,
      simplePieChartOptions: {
        chart: {
          width: "100%",
          type: "pie",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      simpleGroupedBarChartOptions: {
        chart: {
          type: "bar",
          height: 430,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [],
        },
      },
    };
  },
  computed: {
    resolutionStatusChartOptions() {
      let chart = JSON.parse(JSON.stringify(this.simplePieChartOptions));

      chart.labels = ["Resolved", "Unresolved"];

      chart.colors = ["#00c292", "#ff000d"];

      return chart;
    },
    resolutionStatusChartSeries() {
      if (!this.analytics) {
        return [0, 0];
      }

      return [
        this.analytics[0].incidents_resolved,
        this.analytics[0].incidents_unresolved,
      ];
    },
    overdueChartOptions() {
      let chart = JSON.parse(JSON.stringify(this.simplePieChartOptions));

      chart.labels = ["On-time", "Overdue"];

      chart.colors = ["#008ffb", "#FF9800"];

      return chart;
    },
    overdueChartSeries() {
      if (!this.analytics) {
        return [0, 0];
      }

      return [
        this.analytics[0].incidents_count - this.analytics[0].incidents_overdue,
        this.analytics[0].incidents_overdue,
      ];
    },
    categoryOverdueChartOptions() {
      let chart = JSON.parse(JSON.stringify(this.simpleGroupedBarChartOptions));

      chart.xaxis.categories = ["Sponsor", "Elderly", "Doctor", "Other"];

      chart.colors = ["#008ffb", "#FF9800"];

      return chart;
    },
    categoryOverdueChartSeries() {
      if (!this.analytics) {
        return [{ data: [] }, { data: [] }];
      }

      return [
        {
          name: "Total",
          data: [
            this.analytics[0].incidents_for_sponsor,
            this.analytics[0].incidents_for_elderly,
            this.analytics[0].incidents_for_doctor,
            this.analytics[0].incidents_for_other,
          ],
        },
        {
          name: "Overdue",
          data: [
            this.analytics[0].incidents_overdue_for_sponsor,
            this.analytics[0].incidents_overdue_for_elderly,
            this.analytics[0].incidents_overdue_for_doctor,
            this.analytics[0].incidents_overdue_for_other,
          ],
        },
      ];
    },
  },
  methods: {
    fetchAnalytics() {
      this.loading = true;

      this.filters
        .get(endpoints.GET_INCIDENTS_ANALYTICS)
        .then((response) => {
          this.analytics = response;
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchAnalytics();
  },
};
</script>
